import React from 'react'
import { AiOutlineWhatsApp } from "react-icons/ai";
import Digicard from '../images/digicard.png'
const Footer = () => {
    return (
        <div>
            <section className="pb-10 bg-gray-100 px-2 ">
                <div className=" flex-col  border-gray-400 rounded-3xl max-w-[600px] mx-auto flex items-center  p-4  ">
                    {/* <h1 className="max-w-[240px] py-2  text-2xl  leading-tight text-center text-gray-90 text-black" dir='rtl'>כרטיס  זה נבנה על ידי </h1> */}
                    <a href='https://wa.me/+972538250965' className='flex flex-col justify-center items-center hover:scale-125 duration-300 w-[200px] '>
                        <img src={Digicard} alt="logoIcon" style={{ width: '', height: '' }} />
                    </a>
                    <h1 className="text-lg  text-center mt-4 text-black font-black" dir='rtl'>צריכים אחד לעסק שלכם?</h1>

                    {/* <a href='https://wa.me/+972538250965' classNames="px-8 py-3 text- font-bold rounded-full border-white border-2  text-white hover:scale-[110%] hover:bg-gray-800 hover:border-white hover:border-2  hover:bg-violet-500 duration-200 font-medium">DIGICARD</a> */}
                    <a href='http://wa.link/phhfl2' className=' flex mx-auto w-[200px] items-center justify-between bg-black px-3 py-2 mt-4 rounded-full hover:opacity-90'>
                        <a href='http://wa.link/phhfl2' className='flex  items-center'>
                            <div className="group flex items-center justify-center w-10 h-10 rounded-full bg-green-500 hover:scale-[110%] duration-200">
                                <div className="group-hover:scale-[110%]">
                                    <AiOutlineWhatsApp size={30} color='white' />
                                </div>
                            </div>
                        </a>

                        <h1 className="text-lg font-semibold leading-tight text-center text-gray-50" dir='rtl'>שלחו לנו הודעה</h1>
                    </a>

                </div>
            </section>
        </div>
    )
}

export default Footer