import React from 'react'
import CustomAccordion from '../elements/CustomAccordion'
import ContactAccordion from '../elements/ContactAccordion'
const Accordions = ({ header, headerContact, phoneNumber, email, listItems, activityHours, type }) => {


    return (


        <div       data-aos="fade-down"
        data-aos-easing="ease-in-out" className='pb-10 '>
            {/* <CustomAccordion
                header={header}
                listItems={listItems}
                type={type}
            /> */}
            <ContactAccordion
                header={headerContact}
                phoneNumber={phoneNumber}
                email={email}
                type={type}
                activityHours={activityHours} />
        </div>
    )
}

export default Accordions