import React from 'react';
import { FaPlus } from "react-icons/fa";

const SaveAsContact = ({ text, firstName, lastName, phoneNumber, email, type }) => {

    const saveContact = () => {
        const contact = {
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            email: email,
        };

        // Create the vCard content
        const vCardContent = `BEGIN:VCARD
VERSION:3.0
N:${contact.lastName};${contact.firstName};;;
FN:${contact.firstName} ${contact.lastName}
TEL;TYPE=CELL:${contact.phoneNumber}
EMAIL:${contact.email}
END:VCARD`;

        // Create a new Blob with the vCard content
        const blob = new Blob([vCardContent], { type: 'text/vcard' });

        // Create a temporary download link
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = 'contact.vcf';

        // Simulate a click on the download link to trigger the download
        downloadLink.click();

        // Clean up the temporary download link
        URL.revokeObjectURL(downloadLink.href);
        downloadLink.remove();
    };

    return (
        <div>
            {type === 'rounded' ? (
                <div>
                    <button onClick={saveContact} className='bg-gradient-to-r from-saveContactMainColor to-saveContactSecondaryColor flex items-center mt-6 py-2 px-4 justify-around mx-auto rounded-full border-[4px] border-saveContactBorderColor hover:scale-110 duration-300 shadow-lg group'>
                        <FaPlus className='text-saveContact_iconColor text-lg mx-2' />
                        <h1 className='text-saveContact_textColor text-lg font-bold mr-2 group-hover:text-saveContact_textColor'>{text}</h1>
                    </button>
                </div>
            ) : type === 'rectangle' ? (
                <div>
                    <button onClick={saveContact} className='bg-gradient-to-r from-saveContactMainColor to-saveContactSecondaryColor flex items-center mt-6 py-2 px-4 justify-around mx-auto border-[4px] border-saveContactBorderColor hover:scale-110 duration-300 shadow-lg group'>
                        <FaPlus className='text-saveContact_iconColor text-lg mx-2 group-hover:text-saveContact_iconColor' />
                        <h1 className='text-saveContact_textColor text-lg font-bold mr-2 group-hover:text-saveContact_textColor'>{text}</h1>
                    </button>
                </div>
            ) : null}
        </div>
    );
};

export default SaveAsContact;
