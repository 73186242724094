import React from 'react';

const VimeoPresentationsGrid = () => {
    const videos = [
        "https://vimeo.com/985017953",
        "https://vimeo.com/985019487"
    ];

    return (
        <div className='p-4 py-20 specialBg'>
            <h1 data-aos="fade-down" data-aos-easing="ease-in-out" dir='rtl' className="font-bold text-3xl md:text-5xl text-center text-white">
                פרזנטציות
            </h1>
            <h2 data-aos="fade-in" data-aos-easing="ease-in-out" dir='rtl' className="font-medium px-4 my-4 text-base md:text-2xl max-w-[1000px] mx-auto text-center text-white">
                צפו בפרזנטציות שעשיתי
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 gap-y-24 mt-12 max-w-[1200px] mx-auto">
                {videos.map((url, index) => {
                    const vimeoEmbedUrl = `${url.replace('vimeo.com', 'player.vimeo.com/video')}?title=0&byline=0&portrait=0`;
                    return (
                        <div data-aos="fade-down" data-aos-easing="ease-in-out" key={index} className='mx-auto'>
                            <iframe
                                src={vimeoEmbedUrl}
                                className='w-full aspect-video hover:border-gray-400 border-2 shadow-2xl border-bgPath duration-300 hover:border-secondaryColor rounded-2xl'
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default VimeoPresentationsGrid;
