import React, { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'react-headless-accordion';
import { IoIosArrowBack } from 'react-icons/io';

const Accordion1 = ({ type }) => {
    const [rotationState, setRotationState] = useState(false);

    const toggleRotation = () => {
        setRotationState(!rotationState);
    };

    const accordionTransitionDuration = 500; // Set the same duration as the accordion transition

    const accordionClass = () => {
        switch (type) {
            case 'rounded':
                return 'max-w-[500px] mx-auto border-accordionBorderColor bg-gradient-to-r from-accordionMainColor to-accordionSecondaryColor rounded-2xl border-[4px] shadow-lg';
            case 'rectangle':
                return 'max-w-[500px] mx-auto border-accordionBorderColor rounded-none bg-gradient-to-r from-accordionMainColor to-accordionSecondaryColor border-[4px] shadow-lg';
            default:
                return '';
        }
    };

    return (
        <div className='mt-6 mx-auto px-4'>
            <Accordion
                className={accordionClass()}
                transition={{ duration: `${accordionTransitionDuration}ms`, timingFunction: 'cubic-bezier(0, 0, 0.2, 1)' }}
            >
                <AccordionItem>
                    <AccordionHeader
                        className='bg-gradient-to-r from-accordionMainColor to-accordionSecondaryColor items-center text-accordion_headerColor flex p-4 w-full justify-between rounded-2xl'
                        onClick={toggleRotation}
                    >
                        <IoIosArrowBack
                            className={`${rotationState ? 'rotate-90' : ''} text-2xl text-accordion_iconColor`}
                            style={{ transition: `transform 0.5s ease-in-out` }}
                        />
                        <h3 className='text-2xl font-bold'>שירותים בעסק</h3>
                    </AccordionHeader>

                    <AccordionBody>
    <div dir='rtl' className='text-accordionTextColor text-right font-medium p-4 rounded-b-2xl'>
        <p className='text-xl text-blue-200 font-bold'>
            עריכת מצגת בר/בת מצווה:
        </p>
        <ul className='mr-5'>
            <li className='list-disc text-lg font-bold mt-2 text-yellow-200'>
                מצגת באורך שיר אחד - 250₪
            </li>
            <li className='list-disc text-lg font-bold mt-4 text-yellow-200'>
                מצגת באורך שני שירים - 400₪
            </li>
            <li className='list-disc text-lg font-bold mt-4 text-yellow-200'>
                מצגת באורך שלושה שירים - 500₪
            </li>
        </ul>
        <p className='font-bold mt-4'>
            תוצאה - עד 7 ימי עסקים
        </p>
        <p className='text-xl text-blue-200 font-bold mt-4'>
            סרטוני סושיאל לעסק שלך:
        </p>
        <ul className='mr-5'>
            <li className='list-disc text-lg font-bold mt-2 text-yellow-200'>
                סרטון באורך של עד דקה וחצי - 65₪ לסרטון
            </li>
            <p className='font-bold'>
                המחירים יכולים להשתנות ביחס לכמות הסרטונים שהלקוח רוצה.
            </p>
        </ul>
        <p className='text-xl text-blue-200 font-bold mt-4'>
            הוספת כתוביות לסרטון שלך:
        </p>
        <ul className='mr-5'>
            <li className='list-disc text-lg font-bold mt-2 text-yellow-200'>
                30₪ לדקת סרטון
            </li>
        </ul>
        <p className='font-bold mt-4'>
            זמן אספקה תלוי באורך הסרטון.
        </p>
        <p className='font-bold'>
            תוצאה - עד שלושה ימי עסקים
        </p>
        <p className='font-bold mt-4'>
            אני מספק עד 2 סבבי תיקונים.
        </p>
    </div>
</AccordionBody>

                </AccordionItem>
            </Accordion>
        </div>
    );
};

export default Accordion1;
