import React from 'react';

const SocialLink = ({ text, icon: Icon, onClickAction, type }) => {
    const handleClick = () => {
        if (onClickAction) {
            onClickAction();
        }
    };

    return (
        <div className='m-2 w-[80px] justify-center flex flex-col items-center text-center cursor-pointer'>
            {type === 'circleBorder' ? (
                <div onClick={handleClick}>
                    <div className='border-socialLinkBorderColor bg-gradient-to-r from-socialLinkMainColor to-socialLinkSecondaryColor border-2 cursor-pointer mx-auto border-black rounded-full w-12 h-12 flex items-center justify-center hover:scale-110 duration-300'>
                        <Icon className='text-socialLinkIconColor' size={25} />
                    </div>
                    <p className='text-socialLink_textColor text-base font-bold mx-auto'>
                        {text}
                    </p>
                </div>
            ) : type === 'noBg' ? (
                <div onClick={handleClick}>
                    <div className='cursor-pointer mx-auto w-11 h-11 items-center flex justify-center hover:scale-110 duration-300'>
                        <Icon className='text-socialLinkIconColor' size={40} />
                    </div>
                    <p className='text-socialLink_textColor text-base font-bold mx-auto'>
                        {text}
                    </p>
                </div>
            ) : null}
        </div>
    );
};

export default SocialLink;
