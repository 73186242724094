import React from 'react';

const Title = ({ text }) => {
    return (
        <div data-aos="fade-down" data-aos-easing="ease-in-out" className='pt-12'>
            <p className='text-center text-3xl text-title_headerColor font-bold'>
                {text}
            </p>
            <div className='border-[1px] rounded-full w-[300px] border-title_lineColor mx-auto mt-4'></div>
        </div>
    );
}

export default Title;
