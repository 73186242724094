import React from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Pagination, Navigation, EffectCoverflow, Autoplay } from 'swiper/modules';
import { IoIosArrowDroprightCircle, IoIosArrowDropleftCircle } from 'react-icons/io';
import '../styles/imageSwiper.css';

const ImageSwiper = ({ images, type }) => {
    const swiperClass = type === 'rounded' ? 'bg-imageSwiperBgColor rounded-2xl' : 'bg-imageSwiperBgColor';

    return (
        <>
            <div className={swiperClass}>
                <div className='relative swiper1'>
                    <Swiper
                        grabCursor={true}
                        autoplay={{ delay: 800, disableOnInteraction: true }}
                        centeredSlides={true}
                        navigation={{ nextEl: '.arrow-right1', prevEl: '.arrow-left1' }}
                        loop={true}
                        speed={1000}
                        spaceBetween={20}
                        pagination={{
                            clickable: true,
                        }}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                            },
                            640: {
                                slidesPerView: 2,
                            },
                        }}
                        modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
                        className='w-[100%]'
                    >
                        {images && images.map((image, index) => (
                            <SwiperSlide key={index} className='swiper-slide1'>
                                <img src={image} alt={`Image ${index + 1}`} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <button className='arrow-left1 arrow m-2'>
                        <IoIosArrowDropleftCircle size={35} className='text-imageSwiper_iconColor rounded-full hover:opacity-80' />
                    </button>
                    <button className='arrow-right1 arrow m-2'>
                        <IoIosArrowDroprightCircle size={35} className='text-imageSwiper_iconColor rounded-full hover:opacity-80' />
                    </button>
                </div>
            </div>
        </>
    );
}

export default ImageSwiper;
