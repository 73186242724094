import React from 'react';

const About = ({ header, text, callToActionText }) => {
    return (
        <div className='mt-8 mx-4'>
            <h1 className='text-about_headerColor text-lg font-bold text-center underline'>קצת עליי </h1>
            <p dir='rtl' className='text-about_textColor text-lg font-medium text-center mx-auto sm:max-w-[700px]'>
                {text}
            </p>
            <p dir='rtl' className='text-about_callToActionTextColors text-lg font-bold text-center mx-auto mt-2'>{callToActionText}</p>
        </div>
    );
};

export default About;
