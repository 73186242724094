// Banner.jsx
import React from 'react';
import globalStyle from '../styles/globalstyle';

const Banner = ({ type, image }) => {

    return (
        <div>
            {type === 'trapeze' ? (
                <div>
                    <img
                        className={`w-full h-[250px] md:h-[300px]  lg:h-[400px] object-cover clip-custom`}
                        src={image} alt="Banner Image"
                    />
                </div>
            ) : type === 'rectangle' ? (
                <div>
                    <img
                        className={`w-full border-b-2 border-gray-500 shadow-2xl h-[250px] md:h-[300px] lg:h-[400px] object-cover`}
                        src={image}
                        alt="Banner Image"
                    />
                </div>
            ) : null}
        </div>
    );
};

export default Banner;
