import React from 'react';

const Line = () => {
    return (
        <div>
            <div className='border-[1px] rounded-full border-lineColor w-[90%] mx-auto my-10'></div>
        </div>
    );
}

export default Line;
