import React from 'react';

const BusinessName = ({ businessName, businessDescription }) => {
    return (
        <div
            data-aos="fade-down"
            data-aos-delay="400"
            data-aos-easing="ease-in-out"
            className="text-center mt-[150px] md:mt-[200px]"
        >
            <h1 className="text-businessName_nameColor font-bold text-4xl">{businessName}</h1>
            <h1 className="text-businessName_descriptionColor font-bold text-xl">{businessDescription}</h1>
        </div>
    );
};

export default BusinessName;
