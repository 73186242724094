import React from 'react';
import Banner from '../elements/Banner'
import ProfilePicture from '../elements/ProfilePicture'
import BusinessName from '../elements/BusinessName'
import Social from '../components/Social'
import About from '../elements/About'
import SaveAsContact from '../components/SaveAsContact';
import Accordions from '../components/Accordions'
import ImageSwiper from '../elements/ImageSwiper'
import ClientsSwiper from '../elements/ClientsSwiper'
import CallToAction from '../elements/CallToAction'
import Share from '../elements/Share'
import Title from '../elements/Title'
import Line from '../elements/Line'
// Import your image with the correct file path
import ImageSwiperCloser from '../elements/ImageSwiperCloser';

import { bgImageSrc, bannerImageSrc, logoImageSrc, images } from '../media';

import PriceList from '../images/priceList.jpeg'
import LogoReal from '../images/logo.jpeg'
import Accordion1 from '../elements/Accordion1';
import TikTokGrid from '../components/VimeoGrid';
import VimeoGrid from '../components/VimeoGrid';
import VimeoPresentationsGrid from '../components/VimeoPresentaionsGrid';

const HomePage = () => {



    const divStyle = {
        backgroundImage: `url(${bgImageSrc})`, // Note the closing parenthesis
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    };

    return (
        <div className='bg-gray-100'>
            <div className='bg-white w-full md:w-5/6 lg:w-3/4 mx-auto shadow-2xl border-card_borderColor rounded-2xl ' >

                <div style={divStyle}>
                    <Banner
                        type="rectangle"
                        image={bannerImageSrc}
                    />

                    <ProfilePicture
                        type="circleBorder"
                        image={logoImageSrc}

                    />

                    <BusinessName
                        businessName="נירקו הפקות"
                        businessDescription=""
                    />

                    <div className='my-4 p-1 bg-transparent'></div>
                    <Social
                        phoneNumber="+972522074048"
                        phoneText="חייגו אליי"
                        whatsappNumber="+972522074048"
                        facebookUrl="https://www.facebook.com/share/5GSa8phg5wszt5ER/?mibextid=LQQJ4d"
                        locationUrl=""
                        wazeUrl=""
                        wazeText="נווטו "
                        instagramUsername=""
                        tiktokUsername=""
                        shareUrl=""
                        type="circleBorder"
                        email=""

                    />


                    <SaveAsContact
                        text="שמרו אותי באנשי הקשר"
                        phoneNumber="+972522074048"
                        email="nirkirsh@gmail.com"
                        firstName="נירקו הפקות"
                        lastName=""
                        type="rounded" />



                    <About
                        header="קצת עליי"
                        text="שמי ניר קירשבלום, עורך וידיאו בעל נסיון של כשנתיים בתחום. עזרתי כבר להרבה עסקים שיודעים שסרטוני וידאו
ויראליים זה הדבר הבא בשבילם. בנוסף, 
אני עוסק ביצירת מצגות מרגשות לאירועים שהופכים את האירוע ללא פחות ממושלם. 
אני מקצועי, שירותי וחדור מטרה ומבטיח תוצאה מושלמת."
                        callToActionText="מזמין אתכם ליצור קשר בקליק"
                    />



                    {/* <div className='max-w-[500px] p-8 mx-auto'>
                        <img className=' mx-auto my-12  border-8 border-white ' src={PriceList} alt="" />
                    </div> */}

                    <Accordion1 type="rounded" />

                    <Accordions
                        header="התמחות בקליניקה"
                        listItems={[
                            'סידור גבות (טבעיות) ושפם',
                            'טיפולי פנים',
                            'מיצוק העור, צוואר ודקולטה',
                            'הסרת שיער בשעווה',
                            'הסרת שיער בלייזר בכל הגוף לבנות מגיל 8',



                        ]}

                        headerContact="תקשורת ושעות פעילות"
                        phoneNumber="+972522074048"
                        email="nirkirsh@gmail.com"
                        activityHours={[
                            { text: "אני עובד כל יום וכל שעה ביחס לדד ליין של הלקוח.", hours: "" },

                        ]}
                        type="rounded" />

                    <VimeoGrid />
                    <VimeoPresentationsGrid />

                </div>

                <div style={divStyle}>
                    <ImageSwiperCloser
                        type="top"
                        images={images} />
                    <ImageSwiper
                        images={images}
                        type="rounded"
                    />
                    <ImageSwiperCloser
                        type="bottom"
                        images={images} />
                </div>


                <div style={divStyle}>
                    <Title
                        text="לקוחות ממליצים" />

                    <ClientsSwiper
                        testimonials={[
                            {
                                text: "ממליץ בחום על ניר האלוף!!! ערך לי לא מעט סרטונים לפי הדרישות והבקשות שלי, היה סבלני לתיקונים שביקשתי שיבצע ולא התייאש ממני עד שאמרתי שזה מושלם 😜🔥",
                                author: "יונתן וולפגור - חברת פוף זה כל הקסם"
                            },
                            {
                                text: "אני ממליצה בחום על ניר!!! הוא ערך לחברה שלי סרטוני וידאו בצורה מהירה ומאוד מקצועית והיה שירותי מאוד",
                                author: "לילך אברהם - חברת ג'וב שופ"
                            },
                            {
                                text: "פניתי לניר לצורך עריכת סרטון לבר המצווה של בני. העבודה עם ניר היתה מאוד יעילה ונוחה, המחיר הוגן והתוצר מצויין. ממליצה בחום.",
                                author: "חפצי קופלה - מצגת בר מצווה"
                            },
                            {
                                text: "הי רוצה להמליץ על ניר, הכין לי סרטון לבר המצווה מקסים מושקע ערוך יפה ועם מעברים יפים. אין ספק שאפנה אליו בעתיד. עשה עבודה יסודית ונפלאה. מומלץ בחום",
                                author: "דיאן נוי חסון - מצגת בר מצווה"
                            },
                            {
                                text: "פניתי לניר לאחר העליה לתורה של הבן שלי לצורך הכנת מצגת, היה לי חשוב שמי שאעבוד מולו יבין את הראש שלי למה אני מכוונת, פניתי לניר רק לניר היה קליק ראשוני והתקדמנו, עמידה בזמנים דיוק סבלנות אפיינו את העבודה השותפת בנינו, והתוצאה יצאה מושלמת ומרגשת. תודה לניר אני ממליצה מכל הלב!",
                                author: "שרון צור"
                            },
                            {
                                text: "היי ניר, רוצה לומר תודה ענקית על סרטון מקצועי ומושקע. אתגרתי אותך עם שלל תמונות וסרטונים והצלחת לערוך הכל לכדי סרטון מרגש ועוצמתי. כולם היו מרותקים! תודה על הזמינות, הגמישות והרצון הכנה מצידך שאקבל בדיוק מה שרציתי. אמליץ באהבה למכרים 🌷",
                                author: "יפית ליבוביץ’ "

                            }
                        ]}
                    />



                    {/* <Line /> */}

                    {/* <div data-aos="fade-zoom-in" data-aos-easing="linear" data-aos-duration="500" data-aos-delay="800">
                        <h1 dir='rtl' className='text-center px-6 max-w-[600px] mx-auto'>
                            שלום לכם לקוחות יקרים! התחלתי לעבוד עם שירות לקביעת תורים, מעכשיו תוכלו להזמין אצלי תורים בפשטות ובקלות. הורידו את האפליקציה בכפתור למטה, הירשמו וחפשו לפי קוד העסק:
                            <br />
                            <span className='font-bold'> 25670</span>
                        </h1>

                    </div> */}
                    {/* <div className='shake-animation  max-w-[300px] mx-auto'>


                        <a
                            href="https://point-app.net/download/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="group hover:shadow-lg hover:shadow-[#f5cec8]/60 transition ease-in-out hover:scale-105 p-1 rounded-xl bg-gradient-to-br from-[#000] via-[#333] to-[#000] hover:from-[#333] hover:via-[#000] hover:to-[#333] max-w-[300px] mx-auto flex flex-col justify-around items-center text-center border-2 border-[#f5cec8] dir-rtl"
                        >
                            <div className="px-6 py-2 backdrop-blur-xl bg-gradient-to-tr from-black to-[#333] rounded-xl font-bold h-full">
                                <span className="block text-lg mb-1 font-semibold text-[#fff] group-hover:text-[#f5cec8]">
                                    לקביעת תור דרך האפליקציה לחצי כאן
                                </span>
                                <span className="block text-lg mb-1 font-semibold text-[#f5cec8]">
                                    קוד עסק 25670
                                </span>
                                <svg
                                    className="w-6 h-6 stroke-[#f5cec8] group-hover:stroke-[#fff]"
                                    strokeWidth="1.8"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                                        strokeLinejoin="round"
                                        strokeLinecap="round"
                                    ></path>
                                </svg>
                            </div>
                        </a>

                    </div> */}

                    <div data-aos="fade-down"
                        data-aos-easing="ease-in-out">
                        <Line />

                        <CallToAction
                            header="לייעוץ ופרטים נוספים לחצו על הלוגו"
                            phoneNumber="+972522074048"
                            contact="whatsapp"
                            type="circleBorder"
                            image={LogoReal} />

                        <Line />
                    </div>


                    <Share
                        url="https://nirkoproductions.netlify.app/"
                        type="circleBorder"
                    />
                </div>

            </div>
        </div>

    )
}

export default HomePage