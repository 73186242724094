import React, { useState, useEffect } from 'react';
import { IoLogoTiktok, IoLogoInstagram, IoLogoWhatsapp, IoShareSocial, IoLocationOutline } from "react-icons/io5";
import { RiPhoneFill } from 'react-icons/ri';
import { FaFacebookF, FaWaze, FaCar } from "react-icons/fa";
import SocialLink from '../elements/SocialLink';
import { MdOutlineEmail } from "react-icons/md";

const Social = ({ phoneNumber, phoneText, whatsappNumber, facebookUrl, wazeUrl, wazeText, locationUrl, instagramUsername, tiktokUsername, shareUrl, email, type }) => {
    const socialLinksData = [
        { text: phoneText, icon: RiPhoneFill, action: () => handlePhoneClick(phoneNumber), data: phoneNumber },
        { text: 'ווטסאפ', icon: IoLogoWhatsapp, action: () => handleWhatsappClick(whatsappNumber), data: whatsappNumber },
        { text: 'פייסבוק', icon: FaFacebookF, action: () => handleFacebookClick(facebookUrl), data: facebookUrl },
        { text: wazeText, icon: FaCar, action: () => handleWazeClick(wazeUrl), data: wazeUrl },
        { text: 'מיקום', icon: IoLocationOutline, action: () => handleLocationClick(locationUrl), data: locationUrl },
        { text: 'אינסטגרם', icon: IoLogoInstagram, action: () => handleInstagramClick(instagramUsername), data: instagramUsername },
        { text: 'טיקטוק', icon: IoLogoTiktok, action: () => handleTiktokClick(tiktokUsername), data: tiktokUsername },
        { text: 'שיתוף', icon: IoShareSocial, action: () => handleShareUrl(shareUrl), data: shareUrl },
        { text: 'מייל', icon: MdOutlineEmail, action: () => handleEmailClick(email), data: email },

    ].filter(linkData => linkData.data !== null && linkData.data !== undefined && linkData.data !== '');

    const renderSocialLinks = socialLinksData.map((linkData, index) => (
        <SocialLink
            key={index}
            text={linkData.text}
            icon={linkData.icon}
            onClickAction={linkData.action}
            type={type}
        />
    ));

    const [isLargeScreen, setIsLargeScreen] = useState(false);

    useEffect(() => {
        const checkScreenSize = () => {
            setIsLargeScreen(window.innerWidth > 768);
        };

        // Initial check
        checkScreenSize();

        // Event listener for changes in window size
        window.addEventListener('resize', checkScreenSize);

        // Cleanup function to remove event listener when component unmounts
        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);

    const handlePhoneClick = (phoneNumber) => {
        const phoneLink = `tel:${phoneNumber}`;
        window.location.href = phoneLink;
    };

    const handleWhatsappClick = (whatsappNumber) => {
        const phoneNumber = whatsappNumber.replace(/\D/g, '');
        const whatsappLink = `https://wa.me/${phoneNumber}`;

        // Open link in the same tab
        window.location.href = whatsappLink;
    };

    const handleFacebookClick = (facebookUrl) => {
        // Open link in the same tab
        window.location.href = facebookUrl;
    };

    const handleInstagramClick = (instagramUsername) => {
        const instagramLink = `https://www.instagram.com/${instagramUsername}`;

        // Open link in the same tab
        window.location.href = instagramLink;
    };

    const handleTiktokClick = (tiktokUsername) => {
        const tiktokLink = `https://www.tiktok.com/@${tiktokUsername}`;

        // Open link in the same tab
        window.location.href = tiktokLink;
    };

    const handleWazeClick = (wazeUrl) => {
        // Open link in the same tab
        window.location.href = wazeUrl;
    };

    const handleLocationClick = (locationUrl) => {
        // Open link in the same tab
        window.location.href = locationUrl;
    };



    const handleShareUrl = (shareUrl) => {
        // Check if the URL is absolute
        const isAbsoluteUrl = shareUrl.startsWith('http://') || shareUrl.startsWith('https://');

        // If not absolute, make it absolute
        const absoluteUrl = isAbsoluteUrl ? shareUrl : new URL(shareUrl, window.location.href).href;

        if (navigator.share) {
            navigator.share({
                title: 'Digicard',
                text: 'כרטיס ביקור דיגיטלי',
                url: absoluteUrl,
            })
                .then(() => console.log('URL shared successfully'))
                .catch((error) => console.error('Error sharing URL:', error));
        } else {
            // Fallback for browsers that do not support the Web Share API
            // You can implement your custom sharing logic here (e.g., copying to clipboard)
            console.log('Web Share API not supported. Implement custom sharing logic.');
        }
    };


    const handleEmailClick = (email) => {
        const emailLink = `mailto:${email}`;
        window.location.href = emailLink;
    };



    return (
        <div className='mt-4'>
            <div data-aos="fade-down"

                data-aos-easing="ease-in-out">
                {isLargeScreen ? (
                    <div className='w-[99%] flex mx-auto justify-around'>{renderSocialLinks}</div>
                ) : (
                    <>
                        <div className='flex-col flex'>
                            <div className='w-[99%] flex mx-auto justify-around'>{renderSocialLinks.slice(0, 3)}</div>
                            <div className='w-[99%] flex mx-auto justify-around'>{renderSocialLinks.slice(3)}</div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Social;
