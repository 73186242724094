import React from 'react';

const CallToAction = ({ header, phoneNumber, contact, image, type }) => {
    const handlePhoneClick = () => {
        const phoneLink = `tel:${phoneNumber}`;
        window.location.href = phoneLink;
    };

    const handleWhatsappClick = () => {
        const phone = phoneNumber.replace(/\D/g, '');
        const whatsappLink = `https://wa.me/${phone}`;
        window.location.href = whatsappLink;
    };
    const handleClick = contact === 'call' ? handlePhoneClick : handleWhatsappClick;

    return (
        <div className='px-4 mt-8'>
            <h1 className='text-3xl font-bold text-center text-callToAction_headerColor mt-4'>{header}</h1>

            <div className='shake-animation'>
                {type === 'noBg' ? (
                    <div>
                        <img
                            onClick={handleClick}
                            src={image}
                            alt="Logo" className='hover:scale-125 duration-300 mx-auto h-[200px] cursor-pointer my-12'
                        />
                    </div>
                ) : type === 'circleBorder' ? (
                    <div>
                        <img
                            onClick={handleClick}
                            src={image}
                            alt="Logo" className='w-[200px] h-[200px] shadow-2xl shadow-white hover:scale-125 duration-300 border-[4px] border-callToAction_borderColor cursor-pointer mx-auto rounded-full my-12'
                        />
                    </div>
                ) : type === 'circle' ? (
                    <div>
                        <img
                            onClick={handleClick}
                            src={image}
                            alt="Logo" className='w-[200px] h-[200px] hover:scale-125 duration-300 cursor-pointer mx-auto rounded-full my-12'
                        />
                    </div>
                ) : type === 'noBgWide' ? (
                    <div>
                        <img
                            onClick={handleClick}
                            src={image}
                            alt="Logo" className='hover:scale-125 duration-300 mx-auto w-[280px] cursor-pointer my-12'
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default CallToAction;
