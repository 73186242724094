const bgImage = require.context('./images', false, /^\.\/bg\.(jpg|jpeg|png)$/);
const bgImagePath = bgImage.keys()[0]; // Assuming there is only one background image
const bgImageSrc = bgImage(bgImagePath);

const bannerImage = require.context('./images', false, /^\.\/banner\.(jpg|jpeg|png)$/);
const bannerImagePath = bannerImage.keys()[0]; // Assuming there is only one banner image
const bannerImageSrc = bannerImage(bannerImagePath);

const logoImage = require.context('./images', false, /^\.\/logo\.(jpg|jpeg|png)$/);
const logoImagePath = logoImage.keys()[0]; // Assuming there is only one logo image
const logoImageSrc = logoImage(logoImagePath);

const images = [];
const imageContext = require.context('./images/imageSwiper', false, /\.(jpg|jpeg|png)$/);
imageContext.keys().forEach((imagePath) => {
    images.push(imageContext(imagePath));
});



export { bgImageSrc, bannerImageSrc, logoImageSrc, images };
