
import React, { useEffect } from 'react';
import Footer from "./elements/Footer";
import HomePage from "./pages/HomePage";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also import the CSS directly in your JS if your bundler supports it

function App() {

  useEffect(() => {
    AOS.init({
      // Global settings:
      duration: 800, // values from 0 to 3000, with step 50ms
      once: true, // whether animation should happen only once - while scrolling down
      // other options can be found in AOS documentation
    });
  }, []);


  return (
    <div className="App">
      <HomePage />
      <Footer />
    </div>
  );
}

export default App;
