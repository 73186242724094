import React from 'react';

const ProfilePicture = ({ type, image }) => {
    return (
        <div data-aos="fade-down" data-aos-easing="ease-in-out">
            {type === 'circleBorder' ? (
                <div>
                    <img
                        className='border-profilePicture_BorderColor border-2 shadow-2xl w-[150px] h-[150px] md:w-[200px] md:h-[200px] object-cover mx-auto mt-[-30px] absolute right-0 left-0 z-999 rounded-full'
                        src={image}
                        alt="Profile Picture Image"
                    />
                </div>
            ) : type === 'noBg' ? (
                <div>
                    <img
                        className='max-h-[150px] max-w-[300px] md:max-h-[200px] mx-auto mt-[-50px] absolute right-0 left-0 z-999'
                        src={image}
                        alt="Profile Picture Image"
                    />
                </div>
            ) : type === 'circle' ? (
                <div>
                    <img
                        className='w-[150px] h-[150px] md:w-[200px] md:h-[200px] object-cover mx-auto mt-[-50px] absolute right-0 left-0 z-999 rounded-full'
                        src={image}
                        alt="Profile Picture Image"
                    />
                </div>
            ) : type === 'noBgWide' ? (
                <div>
                    <img
                        className='max-h-[150px] max-w-[300px] md:max-h-[200px] md:max-w-[450px] mx-auto mt-8 absolute right-0 left-0 z-999'
                        src={image}
                        alt="Profile Picture Image"
                    />
                </div>
            ) : null}
        </div>
    );
};

export default ProfilePicture;
