import React from 'react';
import SocialLink from './SocialLink';
import { IoLogoWhatsapp, IoMail } from "react-icons/io5";
import { FaFacebookF } from "react-icons/fa";

const Share = ({ url, type }) => {
    const handleShareMailClick = () => {
        const subject = encodeURIComponent('Digicard - כרטיס ביקור דיגיטלי');
        const mailLink = `mailto:?subject=${subject}&body=${encodeURIComponent(url)}`;
        window.location.href = mailLink;
    };

    const handleShareWhatsappClick = () => {
        const message = encodeURIComponent('');
        const whatsappLink = `https://wa.me/?text=${message}%0A${encodeURIComponent(url)}`;
        window.location.href = whatsappLink;
    }

    const handleShareFacebookClick = () => {
        const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&title=${encodeURIComponent('Digicard - כרטיס ביקור דיגיטלי')}&description=${encodeURIComponent('כרטיס ביקור דיגיטלי')}`;
        window.location.href = facebookLink;
    };

    const socialLinksData = [
        { text: '', icon: IoMail, action: handleShareMailClick },
        { text: '', icon: IoLogoWhatsapp, action: handleShareWhatsappClick },
        { text: '', icon: FaFacebookF, action: handleShareFacebookClick },
    ];

    const renderSocialLinks = socialLinksData.map((linkData, index) => (
        <SocialLink
            key={index}
            text={linkData.text}
            icon={linkData.icon}
            onClickAction={linkData.action}
            type={type}
        />
    ));

    return (
        <div data-aos="fade-down" data-aos-easing="ease-in-out" className='pb-20'>
            <div>
                <h1 className='text-center text-share_headerColor text-lg underline font-bold'>שתפו את כרטיס הביקור</h1>
                <div className='w-[200px] flex mx-auto justify-around'>{renderSocialLinks}</div>
            </div>
        </div>
    );
}

export default Share;
