import React, { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'react-headless-accordion';
import { IoIosArrowBack } from 'react-icons/io';

const ContactAccordion = ({ header, phoneNumber, email, activityHours, type }) => {
    const [rotationState, setRotationState] = useState(false);

    const toggleRotation = () => {
        setRotationState(!rotationState);
    };

    const accordionTransitionDuration = 500; // Set the same duration as the accordion transition

    const handlePhoneClick = () => {
        const phoneLink = `tel:${phoneNumber}`;
        window.location.href = phoneLink;
    };

    const handleEmailClick = () => {
        const emailLink = `mailto:${email}`;
        window.location.href = emailLink;
    };

    const accordionClass = () => {
        switch (type) {
            case 'rounded':
                return 'max-w-[500px] mx-auto border-accordionBorderColor bg-gradient-to-r from-accordionMainColor to-accordionSecondaryColor rounded-2xl border-[4px] shadow-lg';
            case 'rectangle':
                return 'max-w-[500px] mx-auto border-accordionBorderColor rounded-none bg-gradient-to-r from-accordionMainColor to-accordionSecondaryColor border-[4px] shadow-lg';
            default:
                return '';
        }
    };

    return (
        <div className='mt-6 mx-auto px-4'>
            <Accordion
                className={accordionClass()}
                transition={{ duration: `${accordionTransitionDuration}ms`, timingFunction: 'cubic-bezier(0, 0, 0.2, 1)' }}
            >
                <AccordionItem>
                    <AccordionHeader
                        className='bg-gradient-to-r from-accordionMainColor to-accordionSecondaryColor items-center text-accordion_headerColor flex p-4 w-full justify-between rounded-2xl'
                        onClick={toggleRotation}
                    >
                        <IoIosArrowBack
                            className={`${rotationState ? 'rotate-90' : ''} text-2xl text-accordion_iconColor`}
                            style={{ transition: `transform 0.5s ease-in-out` }}
                        />
                        <h3 className='text-2xl font-bold'>{header}</h3>
                    </AccordionHeader>

                    <AccordionBody>
                        <div dir='rtl' className='text-accordionTextColor text-right font-medium p-4 rounded-b-2xl'>
                            {phoneNumber && (
                                <>
                                    <h1 className='text-lg font-bold'>טלפון</h1>
                                    <p dir='ltr' className='cursor-pointer mt-2 hover:opacity-80 duration-300' onClick={handlePhoneClick}>{phoneNumber}</p>
                                </>
                            )}
                            {email && (
                                <>
                                    <h1 className='text-lg mt-4 font-bold'>מייל</h1>
                                    <p dir='ltr' className='cursor-pointer mt-2 hover:opacity-80 duration-300' onClick={handleEmailClick}>{email}</p>
                                </>
                            )}
                            {activityHours && activityHours.length > 0 && (
                                <>
                                    <h1 className='text-lg mt-8 font-bold'>שעות פעילות</h1>
                                    {activityHours.map((item, index) => (
                                        <div key={index} className='mt-2'>
                                            <p className='font-bold my-2'>{item.text}</p>
                                            <p className='my-2' dir='ltr'>{item.hours}</p>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        </div>
    );
};

export default ContactAccordion;
