import React from 'react';

const ImageSwiperCloser = ({ type, images }) => {
    const ImageSwiperCloserClass = () => {
        switch (type) {
            case 'top':
                return 'w-full h-8 bg-gradient-to-r from-imageSwiperCloserMainColor to-imageSwiperCloserSecondaryColor rounded-t-3xl';
            case 'bottom':
                return 'w-full h-8 bg-gradient-to-r from-imageSwiperCloserMainColor to-imageSwiperCloserSecondaryColor rounded-b-3xl';
            default:
                return '';
        }
    };

    return (
        <div>
            {images && images.length > 0 ? (
                <div className={ImageSwiperCloserClass()}></div>
            ) : null}
        </div>
    );
};

export default ImageSwiperCloser;
